import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { Router } from '@angular/router';
import {Subscription} from 'rxjs';
import { Title } from 'src/app/models/title';
import { GetTitleResponse, TitlesService } from '../../titles.service';
import {TitlePageService} from '../title-page.service';

@Component({
    selector: 'title-secondary-details-panel',
    templateUrl: './title-secondary-details-panel.component.html',
    styleUrls: ['./title-secondary-details-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TitleSecondaryDetailsPanelComponent implements OnInit, OnDestroy {
    private changeSub: Subscription;
    public description: string;
    public title: Title;
    @Input() genres: any[] = [];

    constructor(
        public titlePage: TitlePageService,
        private cd: ChangeDetectorRef,
        private titles: TitlesService,
        private router:Router
    ) {}

    
    ngOnInit() {
        
        this.changeSub = this.titlePage.changed$.subscribe(() => {
            this.description = (this.titlePage.activeEpisode || this.titlePage.title).description;
            this.cd.markForCheck();
        });

    }
   
    goTo(path:string){
        this.router.navigateByUrl(path);
    }
    //Get all titles
    // getTitles() {
    //     this.titles.getTitles().subscribe((response) => {
    //         this.keyword = response['tags'];
    //     }, (err) => {
    //     });
    // }

    //Get all generes
    // getGeneres() {
    //     this.titles.getGeneres().subscribe((response) => {
    //         this.genres = response['tags'];
    //     }, (err) => {
    //     });
    // }
    
    ngOnDestroy() {
        this.changeSub.unsubscribe();
    }
}
