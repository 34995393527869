<ng-container *ngFor="let person of people|slice:0:limit; last as last">
    <div class="about-outer">
      <img class="aboutimg" [src]="person.poster != null ? person.poster : 'storage/media-images/posters/dqla3nRUC8Y1quZ5Qaz3R2TAgbvLEzdBJm5piJDI.jpeg'" alt="">
      <div class="desc-outer">
        <a class="desc-title" [routerLink]="url.generate(person)">{{person.name}} </a>
        <!-- <a class="capitalize">{{person['pivot']['job']}} </a> -->
      </div>
    </div>
    <!-- <span *ngIf="!last" class="separator">,</span> -->

</ng-container>
