<div class="edit-crew-wrapper">
    <media-item-header></media-item-header>
</div>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<div class="container">
    <router-outlet></router-outlet>
</div>

<footer></footer>
