import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Image } from '../../../models/image';
import { ImageGalleryOverlayComponent } from '../../shared/image-gallery-overlay/image-gallery-overlay.component';
import { ViewportScroller } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CurrentUser } from '@common/auth/current-user';
import { TitlePageService } from './title-page.service';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Settings } from '@common/core/config/settings.service';
import { OverlayPanel } from '@common/core/ui/overlay-panel/overlay-panel.service';
import { List } from 'src/app/models/list';
import { HomepageService } from '../../homepage/homepage.service';
import { MediaViewMode } from '../../shared/media-view/media-view-mode';
import { Store } from '@ngxs/store';
import { Translations } from '@common/core/translations/translations.service';
import { Toast } from '@common/core/ui/toast.service';
import { ShareableNetworks, shareLinkSocially } from '@common/core/utils/share-link-socially';
import { ListState } from '../../lists/state/list-state';
import { shareViaEmail } from '@common/core/utils/share-via-email';
import * as copyToClipboard from 'copy-to-clipboard';
import { MESSAGES } from 'src/app/toast-messages';

@Component({
    selector: 'title-page-container',
    templateUrl: './title-page-container.component.html',
    styleUrls: ['./title-page-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TitlePageContainerComponent implements OnInit {
    @ViewChild(MatTabGroup, { static: true }) matTabGroup: MatTabGroup;
    public map: Map<string, string>;
    lists: List = null;
    modes = MediaViewMode;

    constructor(
        private i18n: Translations,
        private store: Store,
          
        private toast: Toast,
        public settings: Settings,
        private overlay: OverlayPanel,
        private route: ActivatedRoute,
        private homePageService: HomepageService,
        private viewportScroller: ViewportScroller,
        public titlePage: TitlePageService,
        public currentUser: CurrentUser,
        private cd: ChangeDetectorRef,
    ) {

        this.map = new Map([['key', 'value']]);
    }

    ngOnInit() {
        this.titlePage.changed$.subscribe(() => {
            // this.matTabGroup.selectedIndex = 0;
            this.cd.markForCheck();            
        });

        this.homePageService.getLists().subscribe((data) => {
            const resp = data.lists[2];
            this.lists = resp;
            console.log("@@@@@@@@@@@@@@@@@@@@@@", this.lists);
        })


    }

    getGenres() {
        return [...this.titlePage.title.genres, ...this.titlePage.title.keywords];
    }

    public openImageGallery(images: Image[], activeIndex: number) {
        this.overlay.open(ImageGalleryOverlayComponent, {
            origin: 'global',
            position: 'center',
            panelClass: 'image-gallery-overlay-container',
            backdropClass: 'image-gallery-overlay-backdrop',
            hasBackdrop: true,
            data: { images, activeIndex }
        });
    }

    public selectedTabChanged(e: MatTabChangeEvent) {
        this.titlePage.selectedTab$.next(e.index);
    }
    public shareListSocially(type: ShareableNetworks | 'mail' | 'copy') {
        const list = this.store.selectSnapshot(ListState.list);
        const link = this.settings.getBaseUrl(true) + '/lists/' + list.id;

        if (type === 'mail') {
            const siteName = this.settings.get('branding.site_name');
            const subject = this.i18n.t('Check out this link on ') + siteName;
            const body = `${list.name} - ${siteName} - ${link}`;
            shareViaEmail(subject, body);
        } else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        } else {
            shareLinkSocially(type, link, list.name);
        }
    }
}
