<div class="master-home">
  <div class="list-page-media">
    <media-item-header [transparent]="true"></media-item-header>
  </div>
  <slider [list]="sliderList" class="sliderList">

  </slider>


  <ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

  <section class="lists">
    <div class="browse-container">
      <!-- <div class="search-outer">
        <div class="filter-outer">
          <mat-icon svgIcon="sort-icon" class="filter-image"></mat-icon>
          <sort-dropdown [sortOptions]="sortOptions" [dropDownIcon]="true"
            [formControl]="filterFormControl"></sort-dropdown>
        </div>
        <div class="applied-filters">
          <button mat-stroked-button *ngIf="isReset" class="clear-all-filters-button"
            (click)="resetFilters()">
            <span class="text" trans>Reset Filters</span>
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>
        <search-input placeholder="Search titles" class="seachbar search-inner"></search-input>
      </div> -->
    </div>
    <ng-container *ngFor="let list of lists$ | async; let i = index">
      <div class="list">
        <div class="container">
          <h2 class="play">
            <ng-container *ngIf="i != 1;else showPremium">
              <img  class="img"src="client/assets/images/play.png" alt="" >
            </ng-container>
            <ng-template #showPremium>
              <img  class="img"src="client/assets/images/Crown.png" alt="" >
            </ng-template>
            <a>{{ list.name }}</a>
           </h2>
          <p *ngIf="list.title">{{ list.title }}</p>

        </div>
        <media-view  class="view" [mode]="list.style === 'landscape-grid' ? modes.Landscape : modes.Portrait"
        [items]="list.items" [isShowStepslider]="true" class="full-width"
        [showPlayButton]="settings.get('homepage.show_play_buttons')"></media-view>
      </div>


    </ng-container>

    <div class="hot-topics">
      <div class="logoo">
        <img src="client/assets/images/pctv.png">
        <!-- <span class="sub-titles">You’ll receive personalized talk recommendations right here.</span>
        <span class="sub-titles">Already a member? Sign in for more benefits</span> -->
        <div class="section1">
          <span class="title">Hot Topics & Categories?</span>
          <!-- <span class="sub-titles">You’ll receive personalized talk recommendations right here.</span>
          <span class="sub-titles">Already a member? Sign in for more benefits</span> -->
        </div>
      </div>




      <div class="section2">
        <a mat-button class="action-buttons uppercase"
          *ngFor="let cat of hotCategories; index as index" (click)="navigateCategory(cat)"
          trans>{{cat.name}}</a>
      </div>
    </div>
    <no-results-message *ngIf="currentUser.hasPermission('admin') && !(lists$ | async).length"
      svgImage="task-list.svg">
      <span primary-text trans>No homepage content yet.</span>
      <span secondary-text trans
      >Assign some lists to the homepage from &ngsp;
      <a routerLink="/admin/appearance/homepage" [queryParams]="{panel: 'homepage'}"
        >appearance editor</a>
        .</span
      >
    </no-results-message>
  </section>

</div>

<!-- <footer></footer> -->
