import {Component, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
    instagramLink = "https://instagram.com/projectcontrolstv?utm_medium=copy_link";
    facebookLink = "https://www.facebook.com/ProjectControlsTV/";
    youtubeLink = "https://www.youtube.com/channel/UCgcNn2Z5CVCuaYDIvnY9xaw";
    linkedinLink = "https://www.linkedin.com/company/project-controls-tv/about/?viewAsMember=true";
    twitterLink = "https://twitter.com/TvControls?s=20&t=IFTOecXBaDhyY6Z3nTJsJw";
    constructor(public settings: Settings) {}

    public siteName(): string {
        return this.settings.get('branding.site_name');
    }

    public siteDescription(): string {
        return this.settings.get('branding.site_description');
    }

    public year(): number {
        return (new Date()).getFullYear();
    }

    openSocialLink(link) {
        window.open(link);
    }
}
