<div class="footer-content">
  <section class="container">
    <div class="col about-col">
      <div class="title footer" trans>About</div>
      <div class="content ">{{ siteDescription() }}</div>


    </div>

    <custom-menu class="col footer-links" position="footer-1" [showTitle]="true"></custom-menu>
    <custom-menu class="col footer-links" position="footer-2" [showTitle]="true"></custom-menu>
    <custom-menu class="col footer-links" position="footer-3" [showTitle]="true"></custom-menu>
  </section>
</div>

<div class="footer-bar">
  <div class="">
    <div class="footer-content footer-content-wrap">
      <div class="copyright-text" trans>Copyright &copy; {{ siteName() }} {{ year() }}{{'. All rights reserved'}}</div>

      <section class="footer-social">
         <div class="title-uppercase connect"trans>Connect With us :</div>
          <div class="social-icons ">
            <button type="button" mat-icon-button class="social-icon no-style instagram"
              [matTooltip]="'Instagram' | trans" (click)="openSocialLink(instagramLink)">
              <img class="" src="client/assets/images/instagram.png">
            </button>
            <button type="button" mat-icon-button class="social-icon no-style linkedin"
              [matTooltip]="'Linkedin' | trans" (click)="openSocialLink(linkedinLink)">
              <img class="" src="client/assets/images/linkden.png">
            </button>
            <button type="button" mat-icon-button class="social-icon no-style facebook"
              [matTooltip]="'Facebook' | trans" (click)="openSocialLink(facebookLink)">
              <img class="facebook-img" src="client/assets/images/facebook-logo.png" >
            </button>
            <button type="button" mat-icon-button class="social-icon no-style youtube" [matTooltip]="'Youtube' | trans" (click)="openSocialLink(youtubeLink)">
              <img class="" src="client/assets/images/YouTube.png">
            </button>
            <button type="button" mat-icon-button class="social-icon no-style twitter" [matTooltip]="'Twitter' | trans" (click)="openSocialLink(twitterLink)">
              <img class="" src="client/assets/images/Twitter.png">
            </button>

        </div>

      </section>
    </div>

  </div>

    <!-- <app-footer-lang-switcher></app-footer-lang-switcher> -->
</div>
