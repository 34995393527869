<!-- <button  [class.hidden]="itemInWatchlist$ | async" >
    <img class="sharee"src="assets/images/Watchlist.png">
    <span class="action-button-text text" trans>Watchlist</span>

</button> -->
<!-- <button mat-flat-button color="accent" (authClick)="addToWatchlist()" >
    <mat-icon svgIcon="add"></mat-icon>
    <span trans>Add to Watchlist</span>
</button> -->
<div class="whatclisticon">
    <button mat-button (authClick)="addToWatchlist()" [class.hidden]="itemInWatchlist$ | async" [disabled]="loading$ | async" >
       <!-- <mat-icon svgIcon="share"></mat-icon> -->
       <img class="whatclist"src="client/assets/images/Watchlist.png">
       <span class="watch" trans>WATCHLIST</span>
     </button>

     </div>


<button mat-stroked-button (authClick)="removeFromWatchlist()" [class.hidden]="!(itemInWatchlist$ | async)" [disabled]="loading$ | async">
    <mat-icon svgIcon="close"></mat-icon>
    <!-- <span trans>Remove from Watchlist</span> -->
</button>
