<media-item-header></media-item-header>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<mat-sidenav-container class="container main-container">
  <mat-sidenav
    class="sidebar"
    [mode]="breakpoints.isMobile$.value ? 'over' : 'side'"
    [opened]="!breakpoints.isMobile$.value"
    [fixedInViewport]="breakpoints.isMobile$.value"
    #sidenav
  >
    <form [formGroup]="form">
      <div class="filter-container ">
        <label class="filter-header " for="keyword" trans>TV Topics</label>
        <select name="type" class="select" id="type" formControlName="keyword">

          <!-- <option [ngValue]="null" trans>All Types</option> -->
          <!-- <option value="movie" trans>Movie</option>
          <option value="series" trans>TV Series</option> -->
          <!-- <option value="movie" trans>TV Topics</option>
          <option value="series" trans>Categories</option> -->
              <!-- <option [ngValue]="null" trans selected disabled>TV Topics</option> -->
          <option [ngValue]="null" *ngIf="tvTopics.length>0" trans>All</option>
          <option [value]="topic.name" trans *ngFor="let topic of tvTopics; index as index;">{{topic['display_name'].replaceAll("-", " ")}}</option>
        </select>
      </div>

      <div class="filter-container" *ngIf="settings.get('browse.show_streamable_filter')">
        <mat-slide-toggle formControlName="onlyStreamable"class="title" trans>Only Streamable</mat-slide-toggle>
      </div>

      <div class="genres-filter filter-container">
        <div class="filter-header title" trans> Categories</div>
        <mat-selection-list formControlName="genre">
          <mat-list-option
            [value]="genre['name']"
            *ngFor="let genre of genres"
            checkboxPosition="before"
            class="title"
            trans
            >{{ genre['display_name'].replaceAll("-", " ") }}</mat-list-option>
        </mat-selection-list>
      </div>

      <div class="slider-filter filter-container">
        <div class="filter-header title" trans>Year</div>
        <range-slider
          [min]="yearSliderMin"
          [max]="yearSliderMax"
          [step]="1"
          formControlName="released"
        ></range-slider>
      </div>

      <!-- <div class="slider-filter filter-container">
        <div class="filter-header" trans>User Score</div>
        <range-slider
          [min]="1"
          [max]="10"
          [step]="0.1"
          [float]="true"
          formControlName="score"
        ></range-slider>
      </div>

      <div class="slider-filter filter-container">
        <div class="filter-header" trans>Runtime</div>
        <range-slider
          [min]="1"
          [max]="255"
          [step]="1"
          affix="min"
          formControlName="runtime"
        ></range-slider>
      </div> -->


      <div class="filter-container sort-btn-group">
        <div class="filter-header title" trans>Sort by</div>
        <mat-button-toggle-group formControlName="plans" id="plans" aria-label="Plans">

                <mat-button-toggle  class="ss"[value]="'free'">
                  <img  class="free"src='client/assets/images/play.png'> <label class='freee'>{{'Free'}}</label></mat-button-toggle>
                <mat-button-toggle [value]="'premium'"> <img  class="premium"src='client/assets/images/Crown.png'> <label class='premium1'>{{'Premium'}}</label></mat-button-toggle>
              </mat-button-toggle-group>
      </div>

      <div class="filter-container ">
        <div class="filter-header title" trans>Project Controls Expo</div>
        <select id="country" class ="select"formControlName="country">
          <!-- <option [ngValue]="null" trans>All Countries</option> -->
          <option [ngValue]="index == 0 ? null : option['value']" *ngFor="let option of countries; index as index;" trans>
            {{ option.name }}
          </option>
        </select>
      </div>

      <div class="filter-container">
        <div class="filter-header title" trans>Languages</div>
        <select id="language" class ="select" formControlName="language">
          <option [ngValue]="null" trans>All Languages</option>
          <option [value]="option" *ngFor="let option of languages" trans>
            {{ option }}
          </option>
        </select>
      </div>

      <div class="filter-container ">
        <div class="filter-header title" trans>Level</div>
        <!-- <mat-button-toggle-group formControlName="certification" aria-label="Certification">
          <mat-button-toggle [value]="certification" *ngFor="let certification of certifications">{{
            certification
          }}</mat-button-toggle>
        </mat-button-toggle-group> -->
        <select id="certification" class ="select" formControlName="certification">
          <option [ngValue]="null" trans selected >Level</option>
          <option [value]="certification.toLowerCase()" *ngFor="let certification of certifications" trans>
            {{ certification }}
          </option>
        </select>
      </div>

      <button
        class="reset-filters-btn"
        mat-stroked-button
        color="accent"
        (click)="clearAllFilters()"
        [disabled]="!(anyFilterActive$ | async)"
        trans
      >
        <mat-icon svgIcon="refresh"></mat-icon>
        <span trans>Reset Filters</span>
      </button>
    </form>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="browse-container">
      <div class="header">
        <h1 trans>{{filterMenusItems ? filterMenusItems['label'] : 'Browse'}}</h1>
        <!-- <h1 trans>{{ filterMenusItems.length>0 ? "sas" :'Browse'}}</h1> -->
        <div class="applied-filters">
          <button
            class="toggle-filters-button"
            mat-icon-button
            (click)="sidenav.toggle()"
            *ngIf="breakpoints.isMobile$.value"
          >
            <mat-icon svgIcon="tune"></mat-icon>
          </button>
          <button
            mat-stroked-button
            class="clear-all-filters-button"
            color="accent"
            (click)="clearAllFilters()"
            *ngIf="anyFilterActive$ | async"
          >
            <span class="text title" trans>Reset Filters</span>
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        </div>

        <!-- <sort-dropdown
          [formControl]="form.get('order')"
          [sortOptions]="sortOptions"
        ></sort-dropdown> -->
        <media-view-mode-switcher storageKey="browse-titles" #switcher></media-view-mode-switcher>
      </div>
      <div class="media-view-container" *ngIf="pagination$ | async as pagination">
        <media-view [items]="pagination?.data" [isShowStepslider]="false" [switcher]="switcher"></media-view>
        <no-results-message svgImage="searching.svg" *ngIf="pagination?.data?.length === 0">
          <span primary-text trans>Nothing To Display.</span>
          <span secondary-text trans>Seems like no titles match specified filters.</span>
        </no-results-message>
      </div>
      <div class="pagination-container " *ngIf="(pagination$ | async)?.data">
        <button
          type="button"
          class="pagination-btn"
          mat-stroked-button
          (click)="prevPage()"
          [disabled]="!(hasPrev$ | async)"
          *ngIf="(hasPrev$ | async) || (hasNext$ | async)"
          trans
        >
        <!-- <img  class="next"src="client/assets/images/back.png"> -->
        <mat-icon svgIcon="chevron-left"></mat-icon>
        <label class='lb_Nav'> Previous </label>

        </button>
        <button
          type="button"
          mat-raised-button
          class="pagination-btn"
          color="accent"
          [disabled]="!(hasNext$ | async)"
          *ngIf="(hasPrev$ | async) || (hasNext$ | async)"
          (click)="nextPage()"
          trans
        >
        <!-- <img  class="previ"src="client/assets/images/back.png"> -->
        <label class='lb_Nav'> Next</label>
        <mat-icon svgIcon="chevron-right"></mat-icon>


        </button>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<footer></footer>
<loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator>
