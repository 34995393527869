<div class="grid-item">
  <div class="grid-content">
    <media-image
      mode="portrait"
      [src]="title.poster"
      size="medium"
      [alt]="title.name + ' image'"
      (click)="navigate(title)"
    >
      <media-view-play-button [title]="title"></media-view-play-button>
    </media-image>
    <streamable-label [item]="title"></streamable-label>
    <!-- <mat-icon svgIcon="premium" class="premium-icon-title-portrait" *ngIf="!title['is_free']"></mat-icon> -->
    <img src="client/assets/images/crownn.png" class="premium-icon" *ngIf="!title['is_free']">
  </div>
</div>
<div class="grid-label">
  <a class="title" [routerLink]="url.generate(title)">{{ title.name }}</a>
  <!-- <div class="subtitle">
    <genre-widget
      *ngIf="title.genres?.length"
      class="subtitle-item"
      [genres]="title.genres"
      [limit]="2"
      separator="/"
    ></genre-widget>
  </div> -->
</div>
