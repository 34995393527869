<div class="grid-item">
  <div class="grid-content">
    <media-image
      mode="landscape"
      [src]="title.backdrop"
      size="medium"
      [alt]="title.name + ' image'"
      (click)="navigate(title)"
    >
      <media-view-play-button [title]="title"></media-view-play-button>
    </media-image>
    <streamable-label [item]="title"></streamable-label>
    <mat-icon svgIcon="premium" class="premium-icon-title-landscape" *ngIf="!title['is_free']"></mat-icon>
  </div>
</div>
<div class="grid-label">
  <a class="title" [routerLink]="url.generate(title)">{{ title.name }}</a>
  <div class="subtitle">
    <span *ngIf="title.certification" class="subtitle-item certification">{{
      title.certification
    }}</span>
    <genre-widget
      *ngIf="title.genres?.length"
      class="subtitle-item"
      [genres]="title.genres"
      [limit]="2"
      separator=", "
    ></genre-widget>
    <span *ngIf="title.year" class="subtitle-item last"
      >{{ (title.is_series ? 'Series' : 'Movie') | trans }} ({{ title.year }})</span
    >
  </div>
</div>
