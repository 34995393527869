<!-- <div class="current-rating" *ngIf="rating || item?.rating as rating">
    <mat-icon svgIcon="star"></mat-icon>
    <span class="text">{{rating}} / 10</span>
</div> -->

<ng-container *ngIf="showRateButton">
    <!-- <div class="separator" *ngIf="item.rating"></div> -->

    <!-- <button mat-button class="current-rating rate-button" *ngIf="userRating$ | async as userRating; else rateThis" (authClick)="openRatingOverlay($event)">
        <mat-icon svgIcon="star" *ngFor="let in of counter(userRating.score)"></mat-icon>
        <span class="text">
          <span class="score"> {{userRating.score}}</span>
      </span>
    </button> -->
  <span class="text">
    <span class="score">Rate us </span>
  </span>
  <ngx-stars class="rate-custom-star" *ngIf="userRating$" [readonly]="false" [wholeStars]="true" [initialStars]="userRating$ && userRating$['value'] && userRating$['value']['score'] ? userRating$.value.score : 0" [size]="1.5" [maxStars]="5" (ratingOutput)="onRatingSet($event)" authClick></ngx-stars>
    <!-- <ng-template #rateThis>
        <button mat-button class="rate-this rate-button" (authClick)="openRatingOverlay($event)">
            <mat-icon svgIcon="star-border"></mat-icon>
            <span class="text" trans>Rate This</span>
        </button>
    </ng-template> -->
</ng-container>
