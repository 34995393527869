import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MediaViewMode} from '../media-view-mode';
import {LocalStorage} from '@common/core/services/local-storage.service';

@Component({
    selector: 'media-view-mode-switcher',
    templateUrl: './media-view-mode-switcher.component.html',
    styleUrls: ['./media-view-mode-switcher.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaViewModeSwitcherComponent implements OnInit {
    @Input() storageKey: string;
    modeControl = new FormControl();
    selectedListImage = "client/assets/images/list-unselected.png";
    selectedGridImage = "client/assets/images/grid.png";
    constructor(private localStorage: LocalStorage) {}

    ngOnInit() {
        this.modeControl.valueChanges.subscribe(value => {
            this.localStorage.set(`view-mode-${this.storageKey}`, value);
            this.setDefaultImage(value);
        });
        const initial = this.localStorage.get(
            `view-mode-${this.storageKey}`,
            MediaViewMode.Portrait
        );
        this.setDefaultImage(initial);
        this.modeControl.setValue(initial, {emitEvent: false});
    }
    setDefaultImage(value) {
        if (value == "portrait") {
                this.selectedListImage = "client/assets/images/list-unselected.png";
                this.selectedGridImage = "client/assets/images/grid.png";
            } else {
                this.selectedListImage = "client/assets/images/list.png";
                this.selectedGridImage = "client/assets/images/grid-unselected.png";
            }
    }
}
