import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {List} from '../../models/list';
import {Settings} from '@common/core/config/settings.service';
import {CurrentUser} from '@common/auth/current-user';
import {ActivatedRoute, Router} from '@angular/router';
import {MediaViewMode} from '../shared/media-view/media-view-mode';
import {TITLE_SORT_OPTIONS} from '../titles/components/browse-titles/select-options/title-sort-options';
import {FormControl, FormGroup} from '@angular/forms';
import {HomepageService} from './homepage.service';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class HomepageComponent implements OnInit {
    modes = MediaViewMode;
    lists$ = new BehaviorSubject<List[]>([]);
    sliderList: List;
    isReset: boolean = false;
    sortOptions = TITLE_SORT_OPTIONS;
    hotCategories = [];
    public filterFormControl = new FormControl();

    constructor(
        public settings: Settings,
        public currentUser: CurrentUser,
        private route: ActivatedRoute,
        private router: Router,
        private homepage: HomepageService,
    ) {}
        
    ngOnInit() {
        this.bindToFilterControl();
        this.getHotCategories();
        this.route.data.subscribe(data => {
            this.sliderList = JSON.parse(JSON.stringify(data.api.lists.shift()));
            this.lists$.next(data.api.lists);
           // console.log("=============",this.sliderList);
            
        });
    }

    private bindToFilterControl() {
        this.filterFormControl.valueChanges.pipe(
        ).subscribe(titles => {
            if (titles) {
                this.isReset = true;
                var splittedArray = titles.split(":");
                this.getFilteredList(splittedArray[0],splittedArray[1]);
            } else {
                this.isReset = false;
            }
        });
    }

    //Reset filtered list
    resetFilters() {
        this.isReset = false;
        this.filterFormControl.reset();
        this.homepage.getLists().subscribe((response) => {
            response.lists.shift();
            this.lists$.next(response.lists || []);
        }, (err) => {
        });
    }

    //Get filtered list
    getFilteredList(filter, direction) {
        this.homepage.getFilteredLists(filter, direction).subscribe((response) => {
            response.lists.shift();
            this.lists$.next(response.lists || []);
        }, (err) => {
        });
    }

    //Get Hot topics/categories
    getHotCategories() {
        this.homepage.getHotCategories().subscribe((response) => {
            this.hotCategories = response['tags'] || [];
        }, (err) => {
        });
    }

    navigateCategory(cat) {
        this.router.navigateByUrl('/browse?'+cat.type+'='+cat.name);
    }

}
