<ng-content></ng-content>

<ng-container *ngIf="list">
    <div class="slider-track" #sliderTrack (swipeleft)="changeSlide('previous')" (swiperight)="changeSlide('next')">
        <div class="slide" *ngFor="let item of filterTitles(list.items)" [style.background-image]="'url('+ item.backdrop +')'">
            <div class="slide-cover">
                <div class="slide-content container">
                    <!-- <div class="rating" *ngIf="item.rating">
                        <mat-icon svgIcon="star"></mat-icon>
                        {{item.rating}} / 10
                    </div> -->
                    <a class="title" [routerLink]="url.generate(item)" draggable="false">{{item.name}}</a>
                    <div class="description" [moreLessText]="(breakpoints.isMobile$ | async) ? 200 : 350" [textContent]="filterDesignation(item)"></div>
                    <!-- <genre-widget [genres]="item.genres"></genre-widget> -->
                    <button mat-raised-button  class="red-trailer-button red-button-animation" (click)="playVideo(item)" *ngIf="item?.videos?.length">
                        <img  class="red"src="client/assets/images/playred.png">
                        <span *ngIf="item.videos[0].category !== 'full'; else playFull" trans>Play Video</span>
                        <ng-template #playFull>
                            <span trans>Watch Now</span>
                        </ng-template>
                    </button>

                    <div class="share-trailer-button share-button-animation" [matMenuTriggerFor]="shareMenu">
                      <img class="share" src="client/assets/images/share.png">
                      <span trans class="share-text">SHARE</span>
                    </div>

                    <!-- <button mat-button [matMenuTriggerFor]="shareMenu">
                      <img class="sharee" src="client/assets/images/share.png">
                      <span class="action-button-text text" trans>Share</span>
                    </button> -->

                        <!-- <mat-icon svgIcon="share"></mat-icon> -->
                        <!-- <div class="shareicon">

                           <img class="sharee"src="client/assets/images/share.png">
                        <span class="action-button-text" trans>Share</span>

                    </div>
                         -->
                         <!-- <div class="shareicon">
                         <button mat-button [matMenuTriggerFor]="shareMenu" >
                            <img class="sharee"src="client/assets/images/share.png">
                            <span class="action-button-text text" trans>Share</span>
                          </button>
                          </div> -->




                </div>
            </div>
            <div class="header-right">
                <!-- <button mat-button [routerLink]="['/lists', list.id, 'edit']" [disabled]="!canEdit()">
                  <mat-icon svgIcon="edit"></mat-icon>
                  <span class="action-button-text" trans>Edit</span>
                </button>

                <button mat-button [matMenuTriggerFor]="shareMenu" [disabled]="!(public$ | async)">
                  <mat-icon svgIcon="share"></mat-icon>
                  <span class="action-button-text" trans>Share</span>
                </button> -->

                <mat-menu #shareMenu="matMenu" class="list-share-menu">
                  <button mat-menu-item (click)="shareListSocially('facebook',item)">
                    <mat-icon svgIcon="facebook" class="facebook-icon"></mat-icon>
                    <span>Facebook</span>
                  </button>
                  <button mat-menu-item (click)="shareListSocially('twitter',item)">
                    <mat-icon svgIcon="twitter" class="twitter-icon"></mat-icon>
                    <span>Twitter</span>
                  </button>
                  <button mat-menu-item (click)="shareListSocially('mail',item)">
                    <mat-icon svgIcon="mail"></mat-icon>
                    <span trans>Mail</span>
                  </button>
                  <button mat-menu-item (click)="shareListSocially('copy',item)">
                    <mat-icon svgIcon="link"></mat-icon>
                    <span trans>Copy Link</span>
                  </button>
                </mat-menu>
              </div>
        </div>
    </div>

    <div class="slider-controls" *ngIf="list.items.length > 1">
        <button class="control" *ngFor="let item of list.items; index as index;" [class.active]="index === (activeSlide$ | async)" (tap)="changeSlide(index)">
            <span class="control-inner"></span>
        </button>
    </div>
</ng-container>

