<div class="menu-title" *ngIf="showTitle">{{menu.name}}</div>

<ng-container *ngFor="let item of menu.items;index as index;">
  <ng-container *ngIf="shouldShow(item?.condition)">
    <ng-container [ngSwitch]="getItemType(item)">
      <a *ngSwitchCase="'link'" class="nav-item custom-menu-item link-nav-item {{ itemClass }}"
        [href]="item.action" [ngClass]="toSnakeCase(item.label)" [target]="item.target">
        <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
        <span class="text" trans>{{ item.label }}</span>
      </a>
      <ng-container *ngIf="parseRoute(item.action) as route">
        <a *ngSwitchCase="'route'" class="nav-item custom-menu-item route-nav-item {{ itemClass }}"
          [routerLink]="'/' + route.link" [queryParams]="route.queryParams"
          [ngClass]="toSnakeCase(item.label)" routerLinkActive="active"
          [routerLinkActiveOptions]="{exact: item.action === '/' || item.activeExact}"
          [target]="item.target" [matMenuTriggerFor]="menu" (click)="menuTrigger.closeMenu()"
          #menuTrigger="matMenuTrigger"
          (mouseenter)="header && (index ==1 || index == 2) ? mouseEnter(menuTrigger) : mouseLeave()">
          <!-- #menuTrigger="matMenuTrigger" (mouseover)="header && (index ==1 || index == 2) ? menuTrigger.openMenu() : ''"
                > -->
          <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
          <span class="text" trans>{{ item.label }}</span>
        </a>
        <mat-menu #menu="matMenu" class="mat-menu-panel panel" [hasBackdrop]="false">
          <div *ngIf="index === 1" class="capitalize" (mouseleave)="mouseLeave()">
            <div class="mat-menu-item" *ngIf="tvTopics.length>0" (click)="navigate('')">
              {{"All"}}</div>
              <div class="mat-menu-item" *ngFor="let topic of tvTopics" (click)="navigate(topic)">
                {{topic['display_name'].replaceAll("-", " ")}}</div>
          </div>
          <div *ngIf="index === 2" class="capitalize" (mouseleave)="mouseLeave()">
            <div class="mat-menu-item" *ngFor="let genre of genres" (click)="navigate(genre)">
              {{genre['display_name'].replaceAll("-", " ")}}</div>
          </div>
        </mat-menu>
      </ng-container>
    </ng-container>

  </ng-container>
</ng-container>

<ng-content></ng-content>
