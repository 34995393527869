<auth-page infoRowTarget="signin">

  <button type="button" mat-icon-button [matTooltip]="'Close'" class="close-btn" (click)="goBack()">
    <mat-icon svgIcon="close"></mat-icon>
  </button>

    <div class="header" [transValues]="{siteName: settings.get('branding.site_name')}" trans>
        {{ route.snapshot.data.message || 'Create a new account' }}
    </div>
    <form (ngSubmit)="register()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="many-inputs">
            <div class="input-container">
                <label for="first_name" trans>Name</label>
                <input type="text" formControlName="first_name" id="first_name" required>
                <p class="error" *ngIf="errors.first_name">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.first_name}</span>
                </p>
                <!-- <p class="error big-error" *ngIf="form.get('email').value != '' && form.controls.email.status === 'INVALID'">
                  <mat-icon svgIcon="warning"></mat-icon>
                  <span>{{'Please enter valid name'}}</span>
                </p> -->
            </div>
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required>
                <p class="error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.email}}</span>
                </p>
                <p class="error big-error"
                    *ngIf="form.get('email').value != '' && form.controls.email.status === 'INVALID'">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{'Please enter valid email address'}}</span>
                </p>
            </div>
            <div class="input-container">
                <label for="country" trans>Country</label>
                <select id="country" class="select" formControlName="country">
                    <option value="" trans selected>Select Country</option>
                    <option [value]="country.code" trans *ngFor="let country of countries">
                        {{country.name}}
                    </option>
                </select>
                <p class="error big-error"
                    *ngIf="countryError">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{'Please select country'}}</span>
                </p>
            </div>
            <!-- <select id="language" class ="select" formControlName="language">
                    <option [ngValue]="null" trans>All Languages</option>
                    <option [value]="option.code" *ngFor="let option of languages" trans>
                      {{ option.name }}
                    </option>
                  </select>
                 -->


            <div class="input-container">
                <label for="register_password" trans>Password</label>
                <input type="password" formControlName="password" id="register_password" required>
                <p class="error" *ngIf="errors.password">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.password}}</span>
                </p>
            </div>

            <div class="input-container">
                <label for="register_password_confirmation" trans>Confirm Password</label>
                <input type="password" formControlName="password_confirmation" id="register_password_confirmation"
                    required>
            </div>

            <div class="input-container"
                *ngIf="settings.get('envato.enable') && settings.get('envato.require_purchase_code')">
                <label for="purchase_code" trans>Envato Purchase Code</label>
                <input type="text" formControlName="purchase_code" id="purchase_code" required>
                <p class="error" *ngIf="errors.purchase_code">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.purchase_code}}</span>
                </p>
            </div>
        </div>

        <div class="policies" *ngIf="registerPolicies.length">
            <mat-checkbox name="remember-me" class="policy-checkbox" *ngFor="let policy of registerPolicies"
                [formControlName]="policy.id" color="accent" required trans>
                <span trans>I accept the</span>&ngsp;
                <a [href]="policy.type === 'link' ? policy.action : (settings.getBaseUrl() + '/' + policy.action)"
                    *ngIf="policy.action" target="_blank">
                    <strong>{{policy.label | trans}}</strong>
                </a>
                <strong *ngIf=!policy.action>{{policy.label | trans}}</strong>
            </mat-checkbox>
        </div>

        <button mat-raised-button class="primary-button" type="submit" [disabled]="loading$ | async" trans>CREATE
            ACCOUNT</button>
        <div class="input-container">
            <p>
                <span class="login">Or sign in using </span>
            </p>

        </div>

        <ng-container *ngIf="!settings.get('registration.disable')">
            <button type="button" class="no-style alternative-signin-method" (click)="socialAuth.loginWith('envato')"
                *ngIf="settings.get('social.envato.enable'); else defaultSocialAuth">
                <mat-icon svgIcon="envato-custom"></mat-icon>
                <span trans>Sign in with Envato instead</span>
            </button>
            <ng-template #defaultSocialAuth>
                <div class="social-icons center">
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('google')"
                        *ngIf="settings.get('social.google.enable')" class="social-icon no-style google"
                        [matTooltip]="'Login with google' | trans">
                        <img class="googl" src="client/assets/images/google.png">
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('facebook')"
                        *ngIf="settings.get('social.facebook.enable')" class="social-icon no-style facebook"
                        [matTooltip]="'Login with facebook' | trans">
                        <img class="facebook" src="client/assets/images/facebook.png">
                        <!-- <img class="img" src="client/assets/images/google.png"> -->
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('twitter')"
                        *ngIf="settings.get('social.twitter.enable')" class="social-icon no-style twitter"
                        [matTooltip]="'Login with twitter' | trans">
                        <mat-icon svgIcon="twitter"></mat-icon>
                    </button>
                </div>

            </ng-template>
        </ng-container>
    </form>
</auth-page>
