import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Title} from '../../../../../models/title';
import {UrlGeneratorService} from '@common/core/services/url-generator.service';
import {Router} from '@angular/router';
import {CurrentUser} from '@common/auth/current-user';
@Component({
    selector: 'title-landscape-item',
    templateUrl: './title-landscape-item.component.html',
    styleUrls: ['../../styles/media-view-landscape.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleLandscapeItemComponent {
    @Input() title: Title;
    constructor(public url: UrlGeneratorService,
        private router: Router,
        public currentUser: CurrentUser,
    ) { }

    navigate(item) {
        if (item['is_free']){
            this.router.navigate([
                this.url.generate(item)
                    ]);
        } else if(this.currentUser.isSubscribed()){
                     this.router.navigate([
                        this.url.generate(item)
                    ]);
        } else if (this.currentUser.isLoggedIn()) {
                this.router.navigate([
                        'billing/upgrade'
                    ]);
        }else {
            this.router.navigate([
                        'login'
                    ]);
        }
    }
}
