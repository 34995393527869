
<material-navbar menuPosition="primary">

  <search-input class="nav-searchbar"></search-input>

</material-navbar>

  <media-item-header [isFromDetail]="true" [isFree]="titlePage.title?.is_free" [backdrop]="titlePage.title.backdrop">
    <a mat-icon-button [routerLink]="['edit']" *ngIf="currentUser.hasPermission('titles.update')">
      <mat-icon svgIcon="edit"></mat-icon>
    </a>

  </media-item-header>
  <!-- <slider [list]="lists" class="sliderList"></slider> -->





<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>
<div class="container-details">
  <div class="row">
    <div class="col-md-7 col-sm-12 col-xs-12">

      <title-primary-details-panel></title-primary-details-panel>
      <div class="container">
        <current-next-episode-panel *ngIf="titlePage.title.is_series"></current-next-episode-panel>
        <title-secondary-details-panel [genres]="getGenres()"></title-secondary-details-panel>

        <ad-host slot="ads.title" class="title-ad-host"></ad-host>
      </div>

    </div>

    <div class="col-md-5 col-sm-12 col-xs-12">
      <ng-container *ngIf="!titlePage.activeEpisode">
        <!-- <div class="tagline" *ngIf="titlePage.title.tagline">
      <q class="container">{{ titlePage.title.tagline }}</q>
    </div> -->
        <related-titles-panel></related-titles-panel>
      </ng-container>
    </div>
  </div>
  <div>
  <title-videos [isFree]="titlePage.title?.is_free" *ngIf="titlePage.title.show_videos || settings.get('titles.video_panel_mode')">
  </title-videos>
  </div>

  <ng-container *ngIf="lists">
    <div class="list">
      <div class="container">
        <h2 class="play">
          <a [routerLink]="['/lists', lists.id]" class="custom-white">
            <img class="img" src="client/assets/images/Crown.png" alt="">
            {{ lists.name }}</a>
        </h2>
        <p *ngIf="lists.description">{{ lists.description }}</p>
        <!-- <ng-image-slider [images]="imageObject" [imageSize]="{width: 300, height: 250, space:5}" #nav></ng-image-slider> -->
      </div>
      <!-- <media-view [mode]="lists.style === 'landscape' ? modes.Landscape : modes.Portrait"
      [items]="lists.items" [isShowStepslider]="true" class="full-width"
      [showPlayButton]="settings.get('homepage.show_play_buttons')"></media-view> -->
      <media-view  class="view"[mode]="lists.style === 'landscape-grid' ? modes.Landscape : modes.Portrait"
      [items]="lists.items" [isShowStepslider]="true" class="full-width"
      [showPlayButton]="settings.get('homepage.show_play_buttons')"></media-view>
    </div>

  </ng-container>

</div>
<div class="header-right">
  <!-- <button mat-button [routerLink]="['/lists', list.id, 'edit']" [disabled]="!canEdit()">
    <mat-icon svgIcon="edit"></mat-icon>
    <span class="action-button-text" trans>Edit</span>
  </button>

  <button mat-button [matMenuTriggerFor]="shareMenu" [disabled]="!(public$ | async)">
    <mat-icon svgIcon="share"></mat-icon>
    <span class="action-button-text" trans>Share</span>
  </button> -->

  <mat-menu #shareMenu="matMenu" class="list-share-menu">
    <button mat-menu-item (click)="shareListSocially('facebook')">
      <mat-icon svgIcon="facebook" class="facebook-icon"></mat-icon>
      <span>Facebook</span>
    </button>
    <button mat-menu-item (click)="shareListSocially('twitter')">
      <mat-icon svgIcon="twitter" class="twitter-icon"></mat-icon>
      <span>Twitter</span>
    </button>
    <button mat-menu-item (click)="shareListSocially('mail')">
      <mat-icon svgIcon="mail"></mat-icon>
      <span trans>Mail</span>
    </button>
    <button mat-menu-item (click)="shareListSocially('copy')">
      <mat-icon svgIcon="link"></mat-icon>
      <span trans>Copy Link</span>
    </button>
  </mat-menu>
</div>


<!-- <footer class="custom-margin-top"></footer> -->
