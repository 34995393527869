import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '../../../models/title';
import { Person } from '../../../models/person';
import { MEDIA_TYPE } from '../../media-type';
import { MediaViewMode } from './media-view-mode';
import { Episode } from '../../../models/episode';
import { MediaViewModeSwitcherComponent } from './media-view-mode-switcher/media-view-mode-switcher.component';
import { Video } from '../../../models/video';
import { UrlGeneratorService } from '@common/core/services/url-generator.service';
import { CurrentUser } from '@common/auth/current-user';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'media-view',
    templateUrl: './media-view.component.html',
    styleUrls: ['./media-view.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaViewComponent implements OnInit {
    @Input() items: (Title | Person | Episode)[] = [];
    @Input() mode: MediaViewMode = MediaViewMode.Portrait;
    @Input() showPlayButton = false;
    @Input() isShowStepslider = false;
    @Input() switcher: MediaViewModeSwitcherComponent;
    @Output() actionClick = new EventEmitter();
    @HostBinding('class') get modeClass() {
        return `${this.mode}-mode`;
    }
    subscriptions = [];
    constructor(
        private cd: ChangeDetectorRef,
        public url: UrlGeneratorService,
        private router: Router,
        public currentUser: CurrentUser,
    ) { }

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        center: true,
        navSpeed: 600,
        autoplay: true,
        autoHeight: false,
        autoplayHoverPause: true,
        autoplaySpeed: 500,
        navText: ['&#8249', '&#8250;'],
        responsive: {
            0: {
                items: 2
            },
            400: {
                items: 3
            },
            760: {
                items: 4
            },
            1000: {
                items: 5
            }
        },
        nav: true
    };
    dynamicSlides = [
        {
            id: 1,
            src: 'https://via.placeholder.com/600/92c952',
            alt: 'Side 1',
            title: 'Side 1'
        },
        {
            id: 2,
            src: 'https://via.placeholder.com/600/771796',
            alt: 'Side 2',
            title: 'Side 2'
        },
        {
            id: 3,
            src: 'https://via.placeholder.com/600/24f355',
            alt: 'Side 3',
            title: 'Side 3'
        },
        {
            id: 4,
            src: 'https://via.placeholder.com/600/d32776',
            alt: 'Side 4',
            title: 'Side 4'
        },
        {
            id: 5,
            src: 'https://via.placeholder.com/600/f66b97',
            alt: 'Side 5',
            title: 'Side 5'
        }
    ];



    ngOnInit() {
        if (this.isShowStepslider) {
            this.items.map((eachObj) => {
                eachObj['video'] = eachObj['videos'].length ? eachObj['videos'][0]['url'] : '';
                eachObj['posterImage'] = eachObj['poster'];
                eachObj['src'] = eachObj['poster'];
            });
        }
        this.subscriptions = this.currentUser.get("subscriptions");
        if (this.switcher) {
            this.mode = this.switcher.modeControl.value;
            this.switcher.modeControl.valueChanges.subscribe(value => {
                this.mode = value;
                this.cd.markForCheck();
            });
        }
    }

    getImageIndex(index) {
        var navigateUrl = this.url.generate(this.items[index]);
        var isFree = this.items[index]['is_free'] || false;
        if (isFree) {
            this.router.navigate([
                navigateUrl
            ]);
        } else if (this.currentUser.isSubscribed()) {
            this.router.navigate([
                navigateUrl
            ]);
        } else if (this.currentUser.isLoggedIn()) {
            this.router.navigate([
                'billing/upgrade'
            ]);
        } else {
            this.router.navigate([
                'login'
            ]);
        }
    }

    trackByFn = (i: number, item: Title | Person) => item.id;

    isPerson(item: Title | Person) {
        return item.model_type === MEDIA_TYPE.PERSON;
    }
}
