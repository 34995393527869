<section class="left">
    <!-- <div class="img-container">
    <media-image [src]="titlePage.title.poster" size="medium"></media-image>
  </div> -->
    <div class="details">
        <div class="detail with-separator">
            <div class="value">
                <span moreLessText="340" [textContent]="description" *ngIf="description"></span>
                <span *ngIf="!description">{{ 'No overview has been added yet.' | trans }}</span>
            </div>
        </div>


        <div class="detail with-separator about-speaker">
            <div class="name" trans>About Speaker</div>
            <div class="value  disable-text">
                <div *ngIf="titlePage.shortCredits.directors.length; else noabv">
                    <!-- <img class="aboutimg" [src]="titlePage.shortCredits.directors[0].poster" alt=""> -->
                    <people-list-widget class="about-title" [people]="titlePage.shortCredits.directors"></people-list-widget>
                    <!-- <div class="direcorlist">directors</div> -->
                </div>
                <ng-template #noabv>{{ 'No speakers information has been added yet.' | trans }}</ng-template>
            </div>
        </div>

        <div class="detail with-separator relatedTags flex-related-tags">
            <div class="name" trans>Related Tags</div>
            <div class="value">
                <ng-container *ngIf="genres.length; else noTags">
                    <a class="related-tags" *ngFor="let se of genres;let i = last">{{ se.display_name }}</a>
                </ng-container>
                <ng-template #noTags>{{ 'No tags information has been added yet.' | trans }}</ng-template>
                <!-- <a class="related-tags" *ngFor="let season of keyword">{{ season.display_name }}</a> -->
            </div>
        </div>

        <div class="detail with-separator relatedTags flex-related-tags">
            <div class="name" trans>Additonal Tag</div>
            <div class="value">
                <ng-container *ngIf="titlePage.shortCredits.cast.length; else noCast">
                    <a class="related-tags" href="javascript:void(0);" (click)="goTo('/browse?additionaltag=' + se.name)" *ngFor="let se of titlePage.shortCredits.cast;let i = last">{{ se.name }}</a>
                </ng-container>
                <!-- <people-list-widget *ngIf="titlePage.shortCredits.cast.length; else noCast" [people]="titlePage.shortCredits.cast"
        [limit]="5"></people-list-widget> -->
                <ng-template #noCast>{{ 'No cast information has been added yet.' | trans }}</ng-template>
            </div>
        </div>

        <!-- <div class="detail with-separator seasons" *ngIf="!titlePage.activeEpisode && titlePage.title.is_series">
      <div class="name" trans>Seasons</div>
      <div class="value">
        <a class="season" [routerLink]="['season', season.number]"
          *ngFor="let season of titlePage.title.seasons">{{ season.number }}</a>
      </div>
    </div> -->



        <!-- <div class="detail with-separator" *ngIf="titlePage.shortCredits.directors?.length as directorCount">
      <div class="name">
        <span *ngIf="directorCount === 1" trans>Speaker Name</span>
        <span *ngIf="directorCount !== 1" tran> Speaker Name</span>
      </div>
      <div class="value disable-text">
        <people-list-widget [people]="titlePage.shortCredits.directors" [limit]="3">
        </people-list-widget>
      </div>
    </div> -->


        <!-- <div class="detail" *ngIf="titlePage.shortCredits.departments?.length as departmentCount">
      <div class="name">
        <span *ngIf="departmentCount === 1" trans>Department</span>
        <span *ngIf="departmentCount !== 1" trans>About Speaker</span>
      </div>
      <div class="value disable-text">
        <people-list-widget [people]="titlePage.shortCredits.departments" [limit]="1">
        </people-list-widget>
      </div>
    </div> -->

        <!-- <div class="detail with-separator" *ngIf="titlePage.shortCredits.creators?.length">
      <div class="name" trans>Created By</div>
      <div class="value disable-text">
        <people-list-widget [people]="titlePage.shortCredits.creators" [limit]="3">
        </people-list-widget>
      </div>
    </div> -->
        <!-- <div class="detail" *ngIf="titlePage.shortCredits.writers?.length">
      <div class="name" trans>About Speaker</div>
      <div class="value disable-text">
        <people-list-widget [people]="titlePage.shortCredits.writers" [limit]="3">
        </people-list-widget>
      </div>
    </div> -->


        <!-- <div class="detail with-separator">
      <div class="name" trans>Bio</div>
      <div class="value  disable-text">
        <div *ngIf="titlePage.shortCredits.directors.length; else noBio">
          {{titlePage.shortCredits.directors[0]['bio']}}
        </div>
        <ng-template #noBio>{{ 'No bio information has been added yet.' | trans }}</ng-template>
      </div>
    </div> -->
        <!-- <div class="detail with-separator">
      <div class="name" trans>job</div>
      <div class="value  disable-text">
        <div *ngIf="titlePage.shortCredits.cast.length; else noBio">
          {{titlePage.shortCredits.cast}}
        </div>
        <ng-template #noBio>{{ 'No bio information has been added yet.' | trans }}</ng-template>
      </div>
    </div> -->
    </div>
</section>

<!-- <section class="right">
  <title-action-buttons [item]="titlePage.activeEpisode || titlePage.title"></title-action-buttons>
  <div class="video-cover">
    <media-image [src]="titlePage.videoCoverImage" size="large" mode="landscape"></media-image>
    <button mat-fab (click)="titlePage.playVideo(titlePage.primaryVideo)"
      class="play-button-animation" *ngIf="titlePage.primaryVideo">
      <mat-icon svgIcon="play-arrow"></mat-icon>
    </button>
  </div>
</section> -->
