import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Title} from '../../../../models/title';
import {TitlesService} from '../../titles.service';
import {TitlePageService} from '../../title-page-container/title-page.service';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'related-titles-panel',
    templateUrl: './related-titles-panel.component.html',
    styleUrls: ['./related-titles-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatedTitlesPanelComponent implements OnInit, OnDestroy {
    public related: Title[] = [];
    public isShowMoreEnabled = true;
    // need at least 4 related titles to display properly
    private chaneSub: Subscription;
    // @HostBinding('class.hidden') get noRelatedTitles() {
    //     return this.related?.length < 4;
    // }

    constructor(
        private titleApi: TitlesService,
        private titlePage: TitlePageService,
        private cd: ChangeDetectorRef,
        private router:Router
    ) {}

    ngOnInit() {
        this.chaneSub = this.titlePage.changed$.subscribe(() => {
            this.loadRelatedTitles(false);
        });
    }

    ngOnDestroy() {
        this.chaneSub.unsubscribe();
    }

    public loadRelatedTitles(isShowMore) {

        if (isShowMore) {
            this.isShowMoreEnabled = false;
        } else {
            this.isShowMoreEnabled = true;
        }

        this.titleApi.getRelatedTitles(this.titlePage.title, isShowMore ? {limit:100} :{limit: 5}).subscribe(response => {
            this.related = (response.titles);
          //  console.log('############################## ,' , this.related)
            this.cd.markForCheck();
        });
    }

    navigateToDetails(item) {
        this.router.navigateByUrl('/titles/'+item.id+"/"+item.name);
    }

    getJobDetails(casts) {
        var cast = casts.filter(t => t.job.toLowerCase() === 'director');
        if (cast.length > 0) {
            // return cast[0]['name'] + " - " + cast[0]['job'];
            return cast[0]['name'];
        } else {
            return "";
        }
    }
}
