<mat-button-toggle-group [formControl]="modeControl">
  <mat-button-toggle value="portrait">
    <img class="grid" [src]="selectedGridImage">
    <!-- <img class="grid" src="client/assets/images/grid-unselected.png"> -->
  </mat-button-toggle>
  <!-- <mat-button-toggle value="landscape">
    <mat-icon svgIcon="view-column"></mat-icon>
  </mat-button-toggle> -->
  <mat-button-toggle value="detail" class="detail">
    <img class="listview" [src]="selectedListImage">
    <!-- <img class="listview" src="client/assets/images/list-unselected.png"> -->
  </mat-button-toggle>
</mat-button-toggle-group>
