import {
    ChangeDetectionStrategy,
    Component,
    HostBinding,
    Input,
    ViewEncapsulation,
} from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Translations } from '@common/core/translations/translations.service';
import { Toast } from '@common/core/ui/toast.service';
import { ShareableNetworks, shareLinkSocially } from '@common/core/utils/share-link-socially';
import { shareViaEmail } from '@common/core/utils/share-via-email';
import { Store } from '@ngxs/store';
import * as copyToClipboard from 'copy-to-clipboard';
import { ListState } from 'src/app/site/lists/state/list-state';
import { MESSAGES } from 'src/app/toast-messages';
import { TitlePageService } from '../../title-page-container/title-page.service';
import {BreakpointsService} from '@common/core/ui/breakpoints.service';
import {UrlGeneratorService} from '@common/core/services/url-generator.service';

@Component({
    selector: 'media-item-header',
    templateUrl: './media-item-header.component.html',
    styleUrls: ['./media-item-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaItemHeaderComponent {
    @Input() backdrop: string;
    @Input() transparent = false;
    @Input() isFree = false;
    @Input() isFromDetail = false;
    @Input() title = {};
    constructor(
        private i18n: Translations,
        private store: Store,
        public settings: Settings,
        public titlePage: TitlePageService,
        private toast: Toast,
        public breakpoints: BreakpointsService,
        public url: UrlGeneratorService,
    ) { }

    @HostBinding('style.background-image') get backgroundImage() {
        if (this.backdrop) {
            return 'url(' + this.backdrop + ')';
        }
    }

    @HostBinding('class.no-backdrop') get noBackdrop() {
        if (!this.backdrop) {
            return 'no-backdrop';
        }
    }

    public shareListSocially(type: ShareableNetworks | 'mail' | 'copy', item) {
        const list = this.store.selectSnapshot(ListState.list);
        // const link = this.settings.getBaseUrl(true) + '/lists/' + list.id;
        const link = this.settings.getBaseUrl(true) + this.url.generate(item);

        if (type === 'mail') {
            const siteName = this.settings.get('branding.site_name');
            const subject = this.i18n.t('Check out this link on ') + siteName;
            const body = `${list.name} - ${siteName} - ${link}`;
            shareViaEmail(subject, body);
        } else if (type === 'copy') {
            if (copyToClipboard(link)) {
                this.toast.open(MESSAGES.COPY_TO_CLIPBOARD_SUCCESS);
            }
        } else {
            shareLinkSocially(type, link, list.name);
        }
    }
    public filterDesignation(detailItem) {
        var cast = detailItem.credits.filter(t => t.pivot.job.toLowerCase() === 'director');
        return cast.map((elem)=>{
        return elem.name;
        }).join(", ");
    }
}
