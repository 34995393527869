import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {MTDB_CONFIG} from './mtdb-config';
import {AppRoutingModule} from './app-routing.module';
import {SiteModule} from './site/site.module';
import {NgxsModule} from '@ngxs/store';
import {AuthModule} from '@common/auth/auth.module';
import {AccountSettingsModule} from '@common/account-settings/account-settings.module';
import {APP_CONFIG} from '@common/core/config/app-config';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CookieNoticeModule} from '@common/gdpr/cookie-notice/cookie-notice.module';
import {ContactPageModule} from '@common/contact/contact-page.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {LoadingIndicatorModule} from '@common/core/ui/loading-indicator/loading-indicator.module';
import {CORE_PROVIDERS} from '@common/core/core-providers';
import {Bootstrapper} from '@common/core/bootstrapper.service';
import {AppBootstrapperService} from './app-bootstrapper.service';
import {PagesModule} from '@common/pages/shared/pages.module';
import {NotFoundRoutingModule} from '@common/pages/not-found-routing.module';
import {UrlGeneratorService} from '@common/core/services/url-generator.service';
import {AppUrlGeneratorService} from './app-url-generator.service';
import { NgImageSliderModule } from 'ng-image-slider';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { NgxStarsModule } from 'ngx-stars';
// import {FooterComponent} from './footer/footer.component';
// import {MatTooltipModule} from '@angular/material/tooltip';
// import { CustomMenuModule } from '../common/core/ui/custom-menu/custom-menu.module'
// import {TranslationsModule} from '@common/core/translations/translations.module';
import { footerModule } from './footer/footer.module';
 


@NgModule({
    declarations: [AppComponent],
    // exports: [FooterComponent],

    imports: [
        footerModule,
        // TranslationsModule,
        // CustomMenuModule,
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HammerModule,
        HttpClientModule,
        RouterModule.forRoot([], {
            scrollPositionRestoration: 'top',
            relativeLinkResolution: 'legacy',
        }),
        AuthModule,
        AccountSettingsModule,
        AppRoutingModule,
        PagesModule,
        CookieNoticeModule,
        ContactPageModule,
        LoadingIndicatorModule,
        // MatTooltipModule,

        NgxsModule.forRoot([], {developmentMode: false}),

        // need to load these after "NgxsModule.forRoot"
        // as site module contains "NgxsModule.forFeature"
        SiteModule,
        NotFoundRoutingModule,
        NgImageSliderModule,
        CarouselModule,
        // material
        MatSnackBarModule,
        FormsModule,
        CKEditorModule,
        NgxEditorModule,
        NgxStarsModule,
    ],
    providers: [
        ...CORE_PROVIDERS,
        {
            provide: Bootstrapper,
            useClass: AppBootstrapperService,
        },
        {
            provide: UrlGeneratorService,
            useClass: AppUrlGeneratorService,
        },
        {
            provide: APP_CONFIG,
            useValue: MTDB_CONFIG,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
