<div class="list-page-media">
  <media-item-header></media-item-header>
</div>

<ad-host slot="ads.shared" class="shared-ad-host"></ad-host>

<div class="container main-container">
  <ng-container *ngIf="list$ | async as list">
    <div class="list-header">
      <div class="header-left">
        <h1 class="list-name">
          {{ list.system ? (list.name | trans) : list.name }}
        </h1>
        <div class="list-meta">
          <div class="owner-container" *ngIf="list.user">
            <img class="owner-img" [src]="list.user.avatar" alt="" />
            <div>
              <a [routerLink]="['/users', list.user.id]" class="owner-name">{{
                list.user.display_name
              }}</a>

              <div class="dates-container">
                <span>Created - {{ list.created_at | formattedDate }}</span>
                <span class="separator">|</span>
                <span>Updated - {{ list.updated_at | formattedDate }}</span>
              </div>
            </div>
          </div>

          <div class="list-btn-group">
            <button mat-button [routerLink]="['/lists', list.id, 'edit']" [disabled]="!canEdit()">
              <img class="edit" src="client/assets/images/edit.png" />
            </button>

            <button mat-button [matMenuTriggerFor]="shareMenu">
              <img class="share-img" src="client/assets/images/share.png" />
              <span class="action-button-text" trans>Share</span>
            </button>
          </div>
        </div>
      </div>
      <div class="header-right">
        <mat-menu #shareMenu="matMenu" class="list-share-menu">
          <button mat-menu-item (click)="shareListSocially('facebook')">
            <mat-icon svgIcon="facebook" class="facebook-icon"></mat-icon>
            <span>Facebook</span>
          </button>
          <button mat-menu-item (click)="shareListSocially('twitter')">
            <mat-icon svgIcon="twitter" class="twitter-icon"></mat-icon>
            <span>Twitter</span>
          </button>
          <button mat-menu-item (click)="shareListSocially('mail')">
            <mat-icon svgIcon="mail"></mat-icon>
            <span trans>Mail</span>
          </button>
          <button mat-menu-item (click)="shareListSocially('copy')">
            <mat-icon svgIcon="link"></mat-icon>
            <span trans>Copy Link</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="description">{{ list.description }}</div>

    <div class="list-actions" [ngClass]="{'hide-view' : list.name && list.name.toLowerCase() !== 'watchlist'}">
      <div class="meta">
        <div class="count">
          <span trans>Showing</span>
          <span> {{ currentCount$ | async }} </span>
          <span trans>of</span>
          <span> {{ totalCount$ | async }} </span>
          <span trans>items</span>
        </div>
      </div>
      <form class="sort-container" [formGroup]="listForm">
        <div class="input-container">
          <select name="sort" id="sort" formControlName="sortBy">
            <option [value]="option.key" *ngFor="let option of sortOptions | keyvalue" trans>
              {{ option.value }}
            </option>
          </select>
        </div>
      </form>
      <media-view-mode-switcher storageKey="list-page" #switcher></media-view-mode-switcher>
    </div>

    <div class="items">
        <media-view
          *ngIf="(items$ | async)?.length && list.name.toLowerCase() === 'watchlist'"
          [switcher]="switcher"
          [items]="items$ | async"
          [isShowStepslider]="false"
          class="full-width"
        ></media-view>

      <div
        class="form-group"
        *ngIf="(items$ | async)?.length && list.name.toLowerCase() != 'watchlist'"
      >
        <table class="material-table">
          <thead class="title">
            <tr>
              <th trans>Details</th>
              <th trans>Speaker</th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let li of items$ | async" @fadeIn>
              <td>
                <div class="column-with-image">
                  <media-image
                    class="img"
                    mode="portrait"
                    [src]="li.poster"
                    size="medium"
                    [alt]="li.name + ' image'"
                  >
                  </media-image>
                  <div *cdkDragPreview></div>
                  <div class="info">
                    <div class="item-name">{{ li.name }}</div>
                  </div>
                </div>
              </td>
              <td class="rating-column">
                <div class="rating">
                  <ng-container *ngFor="let creds of li.credits; last as last">
                    {{ creds.name }}{{ !last ? ',' : '' }}
                  </ng-container>
                </div>
              </td>
              <td class="rating-column">
                <button
                  mat-raised-button
                  class="red-trailer-button red-button-animation"
                  routerLink="{{ generateUrl(li) }}"
                >
                  <img class="red" src="client/assets/images/playred.png" />
                  <span trans>PLAY</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <no-results-message *ngIf="!(items$ | async)?.length" svgImage="task-list.svg">
        <span primary-text trans>List is Empty</span>
        <span secondary-text trans>This list does not have any items yet.</span>
      </no-results-message>
    </div>
  </ng-container>

  <loading-indicator [isVisible]="loading$ | async" class="overlay"></loading-indicator>
</div>

<!-- <footer></footer> -->
