<material-navbar menuPosition="primary" [transparent]="transparent || !!backdrop" *ngIf="!isFromDetail">

    <search-input class="nav-searchbar"></search-input>

</material-navbar>
<div class="header-overlay" *ngIf="isFromDetail">
    <button mat-fab *ngIf="backdrop && settings.get('streaming.show_header_play') && titlePage.primaryVideo" (click)="titlePage.playVideo(titlePage.primaryVideo)" class="play-button play-button-animation">
    <mat-icon svgIcon="play-arrow"></mat-icon>
  </button>

  <div class="title specific-title">
    {{titlePage.title['name']}}
  </div>

  <div class="description" [moreLessText]="(breakpoints.isMobile$ | async) ? 200 : 350" [textContent]="filterDesignation(titlePage.title)"></div>


    <div class="action-buttons-wrapper">
        <div class="action-buttons">
            <button mat-raised-button color="accent" class="play-trailer-button play-button-animation custom-play-button ppt" (click)="titlePage.playVideo(titlePage.primaryVideo)">
        <img  class="red"src="client/assets/images/playred.png">
        <span trans>
          {{isFree ? 'Play Video' : 'Play Video'}}
        </span>
      </button>


            <title-action-buttons [item]="titlePage.activeEpisode || titlePage.title"></title-action-buttons>

            <div class="shareicon">
                <button mat-button [matMenuTriggerFor]="shareMenu">
                      <!-- <mat-icon svgIcon="share"></mat-icon> -->
                  <img class="sharee"src="client/assets/images/share.png">
                  <span class="action-button-text text" trans>Share</span>
                 </button>

            </div>

        </div>

        <div class="edit-button-position">
            <ng-content></ng-content>
        </div>


    </div>

</div>


<!-- <div mat-raised-button color="accent" class="play-trailer-button play-button-animation custom-play-button ppt"
  [matMenuTriggerFor]="shareMenu">
  <img class="red" src="client/assets/images/share.png">
  <span trans>
    Share
  </span>

</div> -->




<div class="header-right">
    <!-- <button mat-button [routerLink]="['/lists', list.id, 'edit']" [disabled]="!canEdit()">
    <mat-icon svgIcon="edit"></mat-icon>
    <span class="action-button-text" trans>Edit</span>
  </button>

  <button mat-button [matMenuTriggerFor]="shareMenu" [disabled]="!(public$ | async)">
    <mat-icon svgIcon="share"></mat-icon>
    <span class="action-button-text" trans>Share</span>
  </button> -->

    <mat-menu #shareMenu="matMenu" class="list-share-menu">
        <button mat-menu-item (click)="shareListSocially('facebook',titlePage.title)">
      <mat-icon svgIcon="facebook" class="facebook-icon"></mat-icon>
      <span>Facebook</span>
    </button>
        <button mat-menu-item (click)="shareListSocially('twitter',titlePage.title)">
      <mat-icon svgIcon="twitter" class="twitter-icon"></mat-icon>
      <span>Twitter</span>
    </button>
        <button mat-menu-item (click)="shareListSocially('mail',titlePage.title)">
      <mat-icon svgIcon="mail"></mat-icon>
      <span trans>Mail</span>
    </button>
        <button mat-menu-item (click)="shareListSocially('copy',titlePage.title)">
      <mat-icon svgIcon="link"></mat-icon>
      <span trans>Copy Link</span>
    </button>
    </mat-menu>
</div>
