<ng-container [ngSwitch]="mode">
    <div *ngIf="!isShowStepslider">
        <ng-container *ngSwitchCase="'detail'">
            <ng-container *ngFor="let item of items; trackBy: trackByFn">
                <person-detail-item *ngIf="item.model_type === 'person'" [person]="item"></person-detail-item>
                <title-detail-item *ngIf="item.model_type === 'title'" [title]="item"></title-detail-item>
            </ng-container>
        </ng-container>
    </div>
    <div *ngIf="!isShowStepslider">
        <div class="auto-height-grid" *ngSwitchCase="'landscape'">
            <ng-container *ngFor="let item of items; trackBy: trackByFn">
                <person-landscape-item *ngIf="item.model_type === 'person'" [person]="item"></person-landscape-item>
                <title-landscape-item *ngIf="item.model_type === 'title'" [title]="item"></title-landscape-item>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!isShowStepslider">
        <div class="auto-height-grid" *ngSwitchDefault>
            <ng-container *ngFor="let item of items; trackBy: trackByFn">
                <person-portrait-item *ngIf="item.model_type === 'person'" [person]="item"></person-portrait-item>
                <title-portrait-item *ngIf="item.model_type === 'title'" [title]="item"></title-portrait-item>
            </ng-container>
        </div>
    </div>
    <!-- <ng-image-slider *ngIf="isShowStepslider" [images]="items" (imageClick) = "getImageIndex($event)" [imagePopup]="false" [imageSize]="{width: 212, height: 318, space:5}" #nav></ng-image-slider> -->
    <owl-carousel-o [options]="customOptions" *ngIf="isShowStepslider">
        <ng-container *ngFor="let item of items;index as i">
            <ng-template carouselSlide [id]="i+1">
                <img [src]="item.src" [alt]="item.name" [title]="item.name" (click)="getImageIndex(i)">
                <!-- <mat-icon svgIcon="play-arrow" class="play-icon"></mat-icon> -->
                <img src="client/assets/images/crownn.png" class="premium-icon" *ngIf="!item.is_free">
                <div class="owl-grid-label">
                    <a class="title" href="javascript:void(0);">{{item.name}}</a>
                </div>
                <!-- <mat-label>{{item.name}}</mat-label> -->
                <!-- <mat-icon svgIcon="premium" class="premium-icon" *ngIf="!item.is_free"></mat-icon> -->
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</ng-container>