<a class="material-navbar logo" [routerLink]="['/']"><img [src]="logoUrl()" alt="logo"></a>
<div class="auth-page-content">
  <div class="auth-panel">
    <ng-content></ng-content>
  </div>
  <div class="info-row">
    <ng-container *ngIf="infoRowTarget === 'signup' && !settings.get('registration.disable')">
      <span trans>Don't have an account?</span>&ngsp;
      <a class="register-link" [routerLink]="['/register']" trans> <span class="singup-text"> Sign up </span> - it's free, and no credit card required.</a>
    </ng-container>

    <ng-container *ngIf="infoRowTarget === 'signin'">
      <span trans>Already have an account?</span>&ngsp;
      <a [routerLink]="['/login']" trans>Sign in.</a>
    </ng-container>
  </div>
</div>
<div class="custom-menu-container">
  <a routerLink="/" class="copyright">© {{settings.get('branding.site_name')}}</a>
  <custom-menu position="auth-page-footer" [horizontal]="true"></custom-menu>
</div>

<!-- <footer></footer> -->
