import {NgModule} from '@angular/core';
import {FooterComponent} from './footer.component';
import {TranslationsModule} from '@common/core/translations/translations.module';
import {MatTooltipModule} from '@angular/material/tooltip';
// import { CustomMenuModule } from '../common/core/ui/custom-menu/custom-menu.module'
import { CustomMenuModule } from '../../common/core/ui/custom-menu/custom-menu.module'






@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    imports: [TranslationsModule,MatTooltipModule,CustomMenuModule],

})
export class footerModule {}
