<div class="container">
    <!-- <div class="header" trans>More Like This...</div>

    <div class="titles">
        <media-view [items]="related" [isShowStepslider]="false" class="full-width small-titles"></media-view>
    </div> -->

    <strong class="main-title">Recommended videos</strong> <br> <br>
    <ng-container *ngIf="related.length > 0;else showNoRec">
        <ng-container *ngFor="let item of related">
            <div class="card">
                <div class="card-details" (click)="navigateToDetails(item)">
                    <img [src]="item.poster" alt="">
                </div>
                <div class="card-details">
                    <div class="title">{{item.name}}</div>
                    <div class="subtitle">
                        <span class="author-name">{{getJobDetails(item['casts'])}}</span> <br>
                        <!-- <span class="author-position">Director of technical education</span> -->
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="showMore" *ngIf="related.length > 4 && isShowMoreEnabled">
            <button class="show-more" (click)="loadRelatedTitles(true)">
                <span> Show More</span>
                <img src="client/assets/images/play-down.png" alt="">
            </button>
        </div>
    </ng-container>

    <ng-template #showNoRec>
        No recommended videos available for current playing
    </ng-template>
</div>
