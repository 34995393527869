<ng-container *ngIf="pagination$ | async as pagination">
  <no-results-message
    *ngIf="!pagination.data.length && !(loading$ | async)"
    svgImage="like-dislike.svg"
  >
    <span primary-text [transValues]="{user: profile.user$.value.display_name}" trans
      >:user has not rated any titles yet.</span
    >
  </no-results-message>


  <div class="form-group" *ngIf="pagination.data.length && !(loading$ | async)">
    <table class="material-table">
          <thead class="title">
          <tr>
              <th trans>Details</th>
              <th trans>Speaker</th>
              <th trans>Rating</th>

              <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let review of pagination.data;last as last;" @fadeIn >
              <td>
                  <div class="column-with-image">
                    <media-image class="img"
                    mode="portrait"
                    [src]="review.reviewable.poster"
                    size="medium"
                    [alt]="review.reviewable.name + ' image'"
                    [link]="url.generate(review.reviewable)"
                  >
                  </media-image>
                    <div *cdkDragPreview></div>
                    <div class="info">
                        <div class="item-name" [routerLink]="url.generate(review.reviewable)">{{
                          review.reviewable.name
                        }}</div>
                        <!-- <div class="item-type">{{ item?.year }} | {{ item?.type }}</div> -->
                    </div>
                </div>
                </td>
              <td class="rating-column">

               <div class="rating">
                  <ng-container *ngIf="review['peoples']">
                    {{filterSpeaker(review['peoples']) ? filterSpeaker(review['peoples']) : '-'}}
                  </ng-container>
               </div>

            </td>
            <td class="rating-column">

              <div class="rating">
                <ngx-stars class="rate-custom-star" [readonly]="true" [initialStars]="review['score']" [size]="1" [maxStars]="5">
                </ngx-stars>
              </div>

           </td>
          </tr>
          </tbody>
      </table>
      <!-- <ng-container *ngIf="loading$ | async">
        <div *ngFor="let skeleton of skeletonCount" @fadeOut>
          <div class="grid-item">
            <div class="grid-content">
              <skeleton variant="rect"></skeleton>
            </div>
          </div>
          <div class="grid-label">
            <skeleton class="title" variant="text"></skeleton>
            <skeleton class="subtitle" variant="text"></skeleton>
          </div>
        </div>
      </ng-container> -->

  </div>

  <!-- <div class="auto-height-grid">
    <div *ngFor="let review of pagination.data" @fadeIn>
      <div class="grid-item">
        <div class="grid-content">
          <media-image
            mode="portrait"
            [src]="review.reviewable.poster"
            size="medium"
            [alt]="review.reviewable.name + ' image'"
            [link]="url.generate(review.reviewable)"
          >
          </media-image>
        </div>
      </div>
      <div class="grid-label">
        <a class="title" [routerLink]="url.generate(review.reviewable)">{{
          review.reviewable.name
        }}</a>
        <p class="subtitle">
          <rating-widget [rating]="review.score" [showRateButton]="false"></rating-widget>
        </p>
      </div>
    </div>
    <ng-container *ngIf="loading$ | async">
      <div *ngFor="let skeleton of skeletonCount" @fadeOut>
        <div class="grid-item">
          <div class="grid-content">
            <skeleton variant="rect"></skeleton>
          </div>
        </div>
        <div class="grid-label">
          <skeleton class="title" variant="text"></skeleton>
          <skeleton class="subtitle" variant="text"></skeleton>
        </div>
      </div>
    </ng-container>
  </div> -->
</ng-container>
